import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

export const PhilanthropyPageTemplate = ({
  image,
  title,
  name,
  fullText,
}) => (
  <section className="section section--gradient">
    <div className="container">
      <div className="section">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="content">
              <div className="columns">
                <div className="column is-7">
                  <h3 className="has-text-weight-semibold is-size-2">
                    {title}
                  </h3>
                  <p>{name}</p>
                </div>
              </div>
              <div className="columns">
                <div className="column is-7">
                  <h3 className="has-text-weight-semibold is-size-3">
                    {name}
                  </h3>
                  <p>{fullText}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

PhilanthropyPageTemplate.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  fullText: PropTypes.string,
}

const PhilanthropyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <PhilanthropyPageTemplate
        title={frontmatter.title}
        name={frontmatter.name}
      />
    </Layout>
  )
}

PhilanthropyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    }),
  }),
}

export default PhilanthropyPage

export const philanthropyPageQuery = graphql`
  query philanthropyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading

      }
    }
  }
`
